import request from 'superagent';

export const AUTH_TYPES = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

const initialState = {
  user: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN: {
      return {
        ...state,
        user: action.user,
      };
    }
    case AUTH_TYPES.LOGOUT: {
      return {
        ...state,
        user: {},
      };
    }
    default:
      return state;
  }
};

export const login = ({ username, password } = {}) => {
  return (dispatch) => {
    return request
      .post('/api/auth/login')
      .send({
        username,
        password,
      })
      .then((res) => {
        return dispatch({
          type: AUTH_TYPES.LOGIN,
          user: res.body.user,
        });
      });
  };
};

export const updateMe = ({ token, autoPrint, ...me } = {}) => {
  return (dispatch) => {
    return request
      .put('/api/auth/me')
      .send({
        autoPrint: autoPrint ? 1 : 0,
        ...me,
      })
      .then((res) => {
        return dispatch({
          type: AUTH_TYPES.LOGIN,
          user: res.body.user,
        });
      });
  };
};

export const updatePassword = ({ token, currentPassword, password } = {}) => {
  return (dispatch) => {
    return request.put('/api/auth/password').send({
      currentPassword,
      password,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    return request.post('/api/auth/logout').then(() => {
      return dispatch({
        type: AUTH_TYPES.LOGOUT,
      });
    });
  };
};
